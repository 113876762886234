import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
    // Use toast
    const toast = useToast()

    const refUserListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        { key: 'user', label: "Користувач", sortable: true },
        // { key: 'email', label: "E-mail", sortable: true },
        { key: 'role', label: "Роль", sortable: false },
        // {
        //     key: 'currentPlan',
        //     label: 'План',
        //     formatter: title,
        //     sortable: true,
        // },
        // { key: 'status', label: "Статус", sortable: true },
        { key: 'actions', label: "Дії" },
    ]
    const perPage = ref(10)
    const totalUsers = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const roleFilter = ref(null)
    const planFilter = ref(null)
    const statusFilter = ref(null)

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalUsers.value,
        }
    })

    const refetchData = () => {
        refUserListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
        refetchData()
    })

    const fetchUsers = (ctx, callback) => {
        store
            .dispatch('app-user/fetchUsers', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                role: roleFilter.value,
                plan: planFilter.value,
                status: statusFilter.value,
            })
            .then(response => {
                const { users, total } = response.data

                callback(users)
                totalUsers.value = total
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching users list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveUserRoleVariant = role => {
        if (role === 'administrator') return 'warning'
        if (role === 'manager') return 'info'
        return 'primary'
    }

    const resolveUserRoleIcon = role => {
        if (role === 'administrator') return 'SettingsIcon'
        if (role === 'manager') return 'Edit2Icon'
        return 'UserIcon'
    }

    const resolveUserStatusVariant = status => {
        if (status === 'pending') return 'warning'
        if (status === 'active') return 'success'
        if (status === 'inactive') return 'secondary'
        return 'primary'
    }

    const roleOptions = [
        { label: 'Адміністратор', value: 'administrator' },
        { label: 'Менеджер', value: 'manager' },
    ]

    const getRole = role => {

        let roleOption = roleOptions.find(function(option) {
            return option.value == role
        })

        return roleOption.label
    }

    return {
        fetchUsers,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,

        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
        refetchData,
        getRole,

        // Extra Filters
        roleFilter,
        planFilter,
        statusFilter,
        roleOptions,
    }
}